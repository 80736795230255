input {
    background-color: rgba(0,0,0,0.035);
    box-shadow: none;
    -webkit-box-shadow: none;
    border: none;
    position: relative;
    margin: 0;
    font-size: 14px;
    border-bottom: 2px solid #e0e0e0;
    -webkit-transition: border-color 0.2s ease;
    transition: border-color 0.2s ease;
    border-radius: 1px;
    outline: none;
    padding: 10px;
    width: 100%;
    font-size: 14px;
    line-height: 24px;
    color: #555;
    box-sizing: border-box;
}
input:focus {
    border-bottom: 2px solid #7dc8c3;
}
.input-row {
    padding-bottom: 1.5em;
}
.error {
    padding: 2px 6px;
    border: 0px;
    box-shadow: 0 1px 2px rgba(0,0,0,0.2);
    color: #7dc8c3;
    font-size: 1em;
    font-weight: normal;
    display: block;
}
.check-box {
    transform: scale(1.5, 1.5);
    width: 1.5em;
}

.field-icon {
    float: right;
    margin-left: -20px;
    margin-top: -32px;
    position: relative;
    z-index: 2;
    color: gray;
}