.learn-more-btn {
  width: 10em;
  font-size: 22px;
  font-weight: 400;
  color: white;
  background-color: #E8887B;
  border: 2px solid #E8887B;
  border-radius: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  cursor: pointer;
  outline: none;
}

.app-store {
  display: inline-block;
  width: 10em;
  margin: 2px;
  cursor: pointer;
}
.disabled-img {
  opacity: 0.3;
}