form {
  width: 30em;
  margin-left: auto;
  margin-right: auto;
}

.primary-color {
  color: #515151
}

.text-center {
  text-align: center;
}
.small{
  font-size: 15px;
}

.paragraph-margin {
  margin: 0px;
}

.m-5 {
  margin: 20px;
}

.creat-btn {
  width: 100%;
  font-size: 22px;
  font-weight: 400;
  color: #7dc8c3;
  background-color: transparent;
  border: 2px solid #7dc8c3;
  border-radius: 4px;
  padding-top: 15px;
  padding-bottom: 15px;
  cursor: pointer;
  outline: none;
}
.creat-btn:hover {
  background: #7dc8c3;
  color: white;
  box-shadow: 0 20px 38px rgba(0, 0, 0, 0.16);
  -ms-transform: translateY(-3px);
  transform: translateY(-3px);
  -webkit-transform: translateY(-3px);
  transition: all 0.45s cubic-bezier(0.25,1,0.33,1);
  -moz-transition: all 0.45s cubic-bezier(0.25,1,0.33,1);
  -webkit-transition: all 0.45s cubic-bezier(0.25,1,0.33,1);
  outline: none;
}
.terms-text {
  outline: none;
  color:  #7dc8c3;
  text-decoration: none;
  font-weight: normal;
  margin-bottom: 20px;
  display: block;
}
.terms-text:hover {
  outline: none;
}
.terms-text:active {
  outline: none;
}
.conditions {
  padding-left: 10px;
}
.msg {
  font-size: 22px;
  font-weight: 400;
  color: #7dc8c3;
}


.lds-dual-ring {
  display: inline-block;
  width: 20px;
  height: 20px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 20px;
  height: 20px;
  margin-left: 20px;
  border-radius: 50%;
  border: 4px solid gray;
  border-color: gray transparent gray transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
